import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import EventDetails from '../components/event/details/EventDetails'
import {
  createVibe,
  getEventDetailsBySlug,
  removeRsvpForEvent,
  removeVibe,
  rsvpForEvent,
  setReminder
} from '../services/eventService'
import { showToast as showToastNotification } from '../services/toastService'

function EventDetailsContainer() {
  const { eventId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [eventDetails, setEventDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')
  const [vibes, setVibes] = useState([])
  const [savedEvent, setSavedEvent] = useState(false)
  const [rsvp, setRsvp] = useState(false)
  const [isJoinedChatRoom, setJoinedChatRoom] = useState(false)

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventData = await getEventDetailsBySlug(eventId)
        setEventDetails(eventData.data)
        const vibesData = eventData.vibes
        setVibes(eventData.vibes || [])
        const rsvpData = eventData?.data?.Rsvps
        setSavedEvent(vibesData.some((vibe) => vibe.user_id === user?.userId))
        setRsvp(rsvpData.some((rsvp) => rsvp.event_id === eventData?.data?.event_id && rsvp.user_id === user?.userId))
        setJoinedChatRoom(eventData.is_joined_chatroom)

        const pathParts = location.pathname.split('/')
        const influencerIndex = pathParts.indexOf('influencer')
        const influencerId = influencerIndex !== -1 ? pathParts[influencerIndex + 1] : null

        if (influencerId) {
          localStorage.setItem(
            'invite',
            JSON.stringify({ influencerId: influencerId, event: eventId, eventName: eventData.data.name })
          )
        } else {
          localStorage.setItem(
            'invite',
            JSON.stringify({ influencerId: null, event: eventId, eventName: eventData.data.name })
          )
        }
        setLoading(false)
      } catch (error) {
        console.error('Error fetching event details:', error)
        setLoading(false)
      }
    }

    fetchEventDetails()
    // eslint-disable-next-line
  }, [eventId])

  const handleShareEvent = () => {
    const shareableLink = `${window.location.origin}/event-goer/event/${eventDetails.slug || eventId}/influencer/${user.userId}`

    if (navigator.share) {
      navigator
        .share({
          title: 'Check out this event',
          text: `Join ${eventDetails.name} event`,
          url: shareableLink
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error))
    } else if (navigator.clipboard) {
      navigator.clipboard
        .writeText(shareableLink)
        .then(() => {
          console.log('Shareable Link:', shareableLink)
          setMessage('Link copied to clipboard')
          setShowToast(true)
        })
        .catch((error) => console.error('Error copying link:', error))
    } else {
      console.log('Shareable Link:', shareableLink)
    }
  }

  const handleRsvpForRegisteredUser = async () => {
    setLoading(true)
    const rsvp = await rsvpForEvent()
    if (rsvp?.data?.message === 'User has already RSVP') {
      navigate('/promoter/my-event')
    } else {
      setLoading(false)
      setJoinedChatRoom(true)
      setSavedEvent(true)
    }
    setLoading(false)
  }

  const handleNotAttending = async () => {
    setLoading(true)
    const rsvp_id = eventDetails?.Rsvps.find((rsvp) => rsvp.user_id === user?.userId)?.rsvp_id
    if (!rsvp_id) {
      showToastNotification('No ticket found', 'error')
      setLoading(false)
      return
    }
    const rsvp = await removeRsvpForEvent(rsvp_id)
    if (rsvp.success) {
      showToastNotification('You have successfully removed your RSVP', 'success')
      setRsvp(false)
      navigate('/promoter/my-event')
    } else {
      showToastNotification('Error removing RSVP', 'error')
    }
    setLoading(false)
  }

  const handleSaveVibe = async () => {
    if (user?.userId) {
      setLoading(true)
      const data = {
        eventSlug: eventId,
        userId: user.userId
      }
      await createVibe(data)
      setLoading(false)
      showToastNotification('This event has been saved to your vibe page', 'success')
      setSavedEvent(true)
    } else {
      navigate('/login')
    }
  }

  const handleUnsaveVibe = async () => {
    setLoading(true)
    const vibe = vibes.find((vibe) => vibe.user_id === user?.userId)
    if (!vibe) {
      setLoading(false)
      showToastNotification('No vibe found', 'error')
      return
    }
    const response = await removeVibe(vibe.vibe_id)
    if (response.success) {
      showToastNotification('Vibe removed', 'success')
      setSavedEvent(false)
    } else {
      showToastNotification('Error removing vibe', 'error')
    }
    setLoading(false)
  }

  const setReminderHandler = async () => {
    let reminders = JSON.parse(localStorage.getItem('reminders')) || []
    const data = {
      eventId: eventDetails?.event_id
    }
    const response = await setReminder(data)
    setMessage(response.message)

    reminders = [...reminders, { eventId: eventDetails?.event_id }]
    localStorage.setItem('reminders', JSON.stringify(reminders))
    setShowToast(true)
  }

  return (
    <EventDetails
      eventDetails={eventDetails}
      loading={loading}
      user={user}
      handleRsvpForRegisteredUser={handleRsvpForRegisteredUser}
      handleNotAttending={handleNotAttending}
      handleSaveVibe={handleSaveVibe}
      handleUnsaveVibe={handleUnsaveVibe}
      handleShareEvent={handleShareEvent}
      setReminderHandler={setReminderHandler}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
      savedEvent={savedEvent}
      rsvp={rsvp}
      isJoinedChatRoom={isJoinedChatRoom}
    />
  )
}

export default EventDetailsContainer
