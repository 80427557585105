import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formatEventTime } from '../../../services/eventService'
import { LoadingIndicator } from '../../common/loading'
import Header from '../../common/header/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { joinToChatRoom } from '../../../services/chatService'
import { faMessage } from '@fortawesome/free-regular-svg-icons'

function TicketDetails({ user, isRegistered, ticketData, error, loading }) {
  const navigate = useNavigate()

  if (loading) {
    return <LoadingIndicator />
  }
  const handleJoinChat = async () => {
    const payload = {
      eventId: ticketData?.event?.Event?.event_id,
      userId: JSON.parse(localStorage.getItem('user'))?.userId
    }
    const res = await joinToChatRoom(payload)
    if (res.success) {
      navigate(`/chatroom/${res.roomId}`)
    }
  }

  return (
    <div id="ticket-details" className="container">
      <Header showLink={true} />
      <main className="ps-5 pe-5 font-roboto">
        <div className="text-[#070b0f] text-lg font-roboto leading-[25.20px]">Scan your QR code for points</div>
        <div className="text-[#515151] text-sm font-normal font-roboto leading-snug text-justify">
          Meet up with another GreatNight user and scan their code to give them 5 points in their account. If they scan
          your code in return, you'll also earn 5 points. Use the chat room to connect with other GreatNighters at the
          event and start earning points!
        </div>
        <div className="flex justify-center items-center mb-2.5">
          <img
            src={ticketData?.ticket}
            alt={ticketData?.event?.name}
            id="ticket-qr-code"
            className="w-[75%] max-w-screen-lg p-0"
          />
        </div>
        <div className="text-[#515151] text-base font-normal font-roboto leading-snug text-center mb-2">
          Find and connect with people in the group chat. Scan your codes and rack up points!
        </div>
        <div className="h-10 p-3 bg-[#5250ea] rounded-lg flex justify-center items-center mb-2.5">
          <Link onClick={handleJoinChat} id="join-chat">
            <div className="text-center text-white text-sm font-normal font-roboto">
              <FontAwesomeIcon icon={faMessage} className="mr-1" /> Connect Now!
            </div>
          </Link>
        </div>

        <h2 className="mt-4 mb-1 inter-700">Event Details</h2>
        <p className="mb-2 inter-400 date-time">
          {formatEventTime(ticketData?.event?.Event?.start, ticketData?.event?.Event?.end)}
        </p>
        <h3 className="mb-0 mt-4 inter-700">{ticketData?.event?.Event?.venue_name}</h3>
        <p className="mb-2 inter-300 location">
          {ticketData?.event?.Event?.street}, {ticketData?.event?.Event?.city}, {ticketData?.event?.Event?.state},{' '}
          {ticketData?.event?.Event?.country}
        </p>
        <p className="mb-4 mt-4 inter-300" dangerouslySetInnerHTML={{ __html: ticketData?.event?.description }}></p>
      </main>
    </div>
  )
}

export default TicketDetails
