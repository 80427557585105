import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getVibesForUser } from '../../services/eventService'
import Header from '../common/header/Header'
import { showToast } from '../../services/toastService'
import { followUser, getUserProfileByUserId, unfollowUser, updateProfile } from '../../services/userService'
import { EventCard } from '../event/card/item'
import { LoadingIndicator } from '../common/loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

function Vibes() {
  const location = useLocation()
  const navigate = useNavigate()
  const userId = location.pathname.split('/').pop()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const [vibes, setVibes] = useState([])
  const [loading, setLoading] = useState(true)
  const [vibesUser, setVibesUser] = useState(null)
  const [vibeTitle, setVibeTitle] = useState('')
  const [editMode, setEditMode] = useState(false)

  const getVibes = async () => {
    if (userId) {
      const response = await getVibesForUser(userId)
      if (response.success) {
        if (response.data.vibes.length > 0) {
          setVibes(response.data.vibes)
          let tagsData = []
          response.data.vibes.forEach((element) => {
            tagsData.push(element.Event?.tags.split(','))
          })
        }
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const getUserInfo = async () => {
    const myUserId = user?.userId
    if (userId) {
      const response = await getUserProfileByUserId(myUserId, userId)
      if (response.success) {
        setVibesUser(response.data.data)
        setVibeTitle(response?.data?.data?.profile?.vibe_title || '')
      } else {
        console.log(response.message)
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const saveVibeTitle = async () => {
    if (!vibeTitle) {
      showToast('Vibe title cannot be empty', 'error')
      return
    }

    if (vibesUser?.profile?.vibe_title === vibeTitle) return

    const data = { vibe_title: vibeTitle }
    try {
      const res = await updateProfile(data)
      if (res.success) {
        showToast('Vibe title updated successfully', 'success')
      } else {
        showToast(res.message, 'error')
      }
    } catch (error) {
      showToast('Failed to update vibe title', error.message)
    }
  }

  useEffect(() => {
    getVibes()
    getUserInfo()
    // eslint-disable-next-line
  }, [userId])

  if (loading) {
    return <LoadingIndicator />
  }

  const handleFollow = async (follower_id, name) => {
    const userId = user?.userId
    if (userId) {
      if (userId === follower_id) {
        showToast('You cannot follow yourself', 'error')
      }
      const response = await followUser(userId, follower_id)
      if (response.success) {
        showToast(`You are now following ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            event.Event.User.is_following = 1
          }
          return event
        })
        setVibes(updatedEvents)
        setVibesUser({ ...vibesUser, follow: true })
      } else {
        showToast(response.message, 'error')
      }
    } else {
      navigate('/')
    }
  }

  const handleUnfollow = async (follower_id, name) => {
    const userId = user?.userId
    if (userId) {
      if (userId === follower_id) {
        showToast('You cannot unfollow yourself', 'error')
      }
      const response = await unfollowUser(userId, follower_id)
      if (response.success) {
        showToast(`You have unfollowed ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            if (event.Event && event.Event.User) {
              event.Event.User.is_following = 0
            }
          }
          return event
        })
        setVibes(updatedEvents)
        setVibesUser({ ...vibesUser, follow: false })
      } else {
        showToast(response.message, 'error')
      }
    } else {
      navigate('/')
    }
  }

  const handleShareEvent = () => {
    if (userId) {
      const shareableLink = `${window.location.origin}/promoter/vibes/influencer/${userId}`

      if (navigator.share) {
        navigator
          .share({
            title: 'Check out this vibe',
            text: `Visit ${vibesUser?.profile?.username}'s vibe page`,
            url: shareableLink
          })
          .then(() => console.log('Shared successfully'))
          .catch((error) => console.error('Error sharing:', error))
      } else if (navigator.clipboard) {
        navigator.clipboard
          .writeText(shareableLink)
          .then(() => {
            console.log('Shareable Link:', shareableLink)
          })
          .catch((error) => console.error('Error copying link:', error))
      } else {
        console.log('Shareable Link:', shareableLink)
      }
    } else {
      navigate('/')
    }
  }

  return (
    <div id="events">
      <Header title="Vibes" showLink={true} />
      <main>
        <div className="flex justify-between">
          <div className="ml-4 auto-rows-max text-center flex items-center">
            <div className="col-span-2">
            <div className="h-24 w-24 overflow-hidden mb-4 rounded-full">
              <img
                src={
                  vibesUser?.profile?.profile_picture_url
                    ? vibesUser.profile.profile_picture_url
                    : '/assets/images/default_pfp.png'
                }
                alt="Profile"
                className="h-full w-full "
              />
              </div>
          
              <p className="text-left col-span-2 text-2xl font-bold ">{vibesUser?.profile?.username}</p>
              <p className="text-left text-base mt-3">{vibesUser?.profile?.about_me}</p>
            </div>
          </div>
          <div className="justify-end">
            <button
              className="text-decoration-none button text-white block text-center m-4 mr-4 button--primary"
              type="button"
              onClick={() =>
                vibesUser?.follow
                  ? handleUnfollow(vibesUser?.profile?.user_id, vibesUser?.profile?.username)
                  : handleFollow(vibesUser?.profile?.user_id, vibesUser?.profile?.username)
              }
            >
              {vibesUser?.follow ? 'Unfollow' : 'Follow'}
            </button>
          </div>
        </div>
        <div className="flex justify-between ml-2">
          {!editMode && <p className="text-left text-base mt-3 ml-2">{vibeTitle}</p>}
          {parseInt(userId) === parseInt(user?.userId) && !editMode && (
            <p className="text-left text-sm mt-3 cursor-pointer" onClick={() => setEditMode(true)}>
              {vibeTitle ? 'Edit' : 'Add Vibe Title'}
            </p>
          )}
        </div>

        {parseInt(userId) === parseInt(user?.userId) && editMode && (
          <div className="flex justify-between mt-3 ml-2">
            <input
              type="vibe-title"
              id="vibe-title"
              className="block w-full p-3 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ml-2"
              placeholder="vibe title"
              value={vibeTitle}
              onChange={(e) => setVibeTitle(e.target.value)}
              required
            />
            <button
              className="text-decoration-none button text-white block text-center m-4 mx-4 button--primary"
              type="button"
              onClick={() => {
                const title = document.getElementById('vibe-title').value
                setVibeTitle(title)
                setEditMode(false)
                saveVibeTitle()
              }}
            >
              Save
            </button>
          </div>
        )}

        {vibes?.length > 0 && (
          <>
            <hr className="mt-5" />
            <hr className="mb-3" />

            <div className="text-center mb-4">
              <Link
                onClick={handleShareEvent}
                className="text-decoration-none button button--tertiary text-white block text-center"
                id="share-event"
              >
                <FontAwesomeIcon icon={faCopy} /> Share This Vibe
              </Link>
            </div>

            {vibes.map((vibe, index) => (
              <EventCard
                key={index}
                event={vibe.Event}
                onFollowUpdate={(data) =>
                  data.action === 'unfollow'
                    ? handleUnfollow(data.user_id, data.name)
                    : handleFollow(data.user_id, data.name)
                }
                showFollowButton={false}
                showUserName={false}
              />
            ))}
          </>
        )}
      </main>
    </div>
  )
}

export default Vibes
