import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import './Profile.css'
import Toasts from '../common/toasts/Toasts'
import Cookies from 'js-cookie'
import { LoadingIndicator } from '../common/loading'
import Header from '../common/header/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { uploadImage } from '../../services/eventService'
import { getInterests } from '../../services/interestService'
import { classNames } from '../../utils'
import { AGES } from '../../config/userData'

function Profile({
  loading,
  onSubmit,
  user,
  formData,
  handleChange,
  handleInputBlur,
  errors,
  onUpdateProfile,
  setShowToast,
  showToast,
  toastMessage,
  status,
  follows
}) {
  const navigate = useNavigate()
  const isFormValid = !Object.values(errors).some((error) => error)
  const [isImgUploading, setIsImgUploading] = useState(false)
  const [preview, setPreview] = useState(user?.profileImgUrl || '')
  const userTags = user?.interests ? user.interests.split(',') : []
  const [selectedTags, setSelectedTags] = useState(userTags)
  const [tags, setTags] = useState([])

  const fetchTags = async () => {
    try {
      const data = await getInterests()
      if (data && Array.isArray(data)) {
        setTags(data.map((tag) => tag.name))
      } else {
        setTags([])
      }
    } catch (error) {
      console.log('🚀 ~ fetchTags ~ error:', error)
      setTags([])
    }
  }

  useEffect(() => {
    fetchTags()
  }, [])

  if (loading) {
    return <LoadingIndicator />
  }

  const handleImageChange = async (e) => {
    setIsImgUploading(true)
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
    try {
      const imgFormData = new FormData()
      imgFormData.append('image', file)
      const url = await uploadImage(imgFormData)
      console.log('🚀 ~ handleImageChange ~ url:', url)
      handleChange({ target: { name: 'profileImgUrl', value: url.data.imageUrl } })
    } catch (error) {
      console.log('🚀 ~ handleImageChange ~ error:', error)
    } finally {
      setIsImgUploading(false)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(formData)
  }

  const logout = () => {
    localStorage.clear()
    Cookies.remove('gn_userToken')
    navigate('/')
  }

  return (
    <div id="profile-wrapper" className="w-full">
      <Header showLink={true} />
      <div className="container">
        <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
        <div className="text-center text-zinc-800 text-xl font-medium font-roboto leading-7 mb-6">Profile</div>
        <section className="mt-5">
          <form>
            <div className="w-[361px] h-[68px] justify-between items-center inline-flex mb-5">
              <div className="h-[68px] justify-center items-center gap-3 flex">
                <div
                  className="relative cursor-pointer"
                  onClick={() => document.getElementById('profileImageInput').click()}
                  disabled={isImgUploading}
                >
                  {preview ? (
                    <div className="relative">
                      <img src={preview} alt="Profile Preview" className="w-[68px] h-[68px] rounded-full" />
                      <FontAwesomeIcon
                        className="absolute bottom-0 right-0 bg-white rounded-full p-1 size-4"
                        icon={faCamera}
                      />
                    </div>
                  ) : (
                    <div className="relative">
                      <div className="w-[68px] h-[68px] rounded-full flex items-center justify-center bg-gray-200 text-gray-500 text-xs">
                        No Image
                      </div>
                      <FontAwesomeIcon
                        className="absolute bottom-0 right-0 bg-white rounded-full p-1 size-4 cursor-pointer hover:bg-gray-100"
                        icon={faCamera}
                        onClick={(e) => {
                          e.stopPropagation() // Prevents triggering the parent's onClick
                          document.getElementById('profileImageInput').click()
                        }}
                      />
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  id="profileImageInput"
                  onChange={handleImageChange}
                  className="hidden"
                />

                <div className="flex-col justify-start items-center gap-1 inline-flex">
                  <div className="self-stretch text-[#060a0f] text-xl font-medium font-roboto leading-7">
                    {user?.fullName}{' '}
                  </div>
                  <div className="self-stretch text-[#8c9096] text-sm font-normal font-roboto">{user?.email}</div>
                </div>
              </div>
              <div className="px-7 py-1 rounded-[40px] border border-[#e0e0e0]/60 justify-center items-center gap-2 flex">
                {/* <div className="w-4 h-4 justify-center items-center flex text-sm">
                  <Link>
                    <FontAwesomeIcon className="m-1 size-3" icon={faEdit} />
                  </Link>{' '}
                  Edit
                  <div className="w-4 h-4 relative">
                    <div className="w-[8.78px] h-[8.78px] left-[5.22px] top-[2.67px] absolute"></div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <h1 className="inter-700 mb-3">Profile</h1>
              <div>
                <table className="table border-spacing-x-6 border-separate">
                  <tbody className="text-left">
                    {user && user.fullName && (
                      <tr>
                        <th className="inter-500">Full Name</th>
                        <td className="inter-300">{user?.fullName}</td>
                      </tr>
                    )}
                    <tr>
                      <th className="inter-500">Email</th>
                      <td className="inter-300">{user?.email}</td>
                    </tr>
                    <tr>
                      <th className="inter-500">Role</th>
                      <td className="inter-300 text-capitalize">{user?.role}</td>
                    </tr>
                    <tr>
                      <th className="inter-500">Status</th>
                      <td className="inter-300">{user?.status}</td>
                    </tr>
                    <tr>
                      <th className="inter-500">Total Points</th>
                      <td className="inter-300">{status?.totalPoints}</td>
                    </tr>
                    <tr>
                      <th className="inter-500">Followers</th>
                      <td className="inter-300">{follows?.follows}</td>
                    </tr>
                    <tr>
                      <th className="inter-500">Following</th>
                      <td className="inter-300">{follows?.following}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            */}
            <div className="mt-5 mb-5">
              <label htmlFor="name" className="block mb-2 text-sm font-bold">
                Username
              </label>
              <label htmlFor="name" className="block mb-2 text-xs font-normal text-gray-500">
                What do you want people on the app to call you?
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="Username"
                required
                readOnly
              />
            </div>
            <div className="mb-5">
              <label htmlFor="email" className="block mb-2 text-sm font-bold">
                Email
              </label>
              <label htmlFor="email" className="block mb-2 text-xs font-normal text-gray-500">
                What's the best email to receive notifications from GreatNight?
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="Email"
                required
                readOnly
              />
            </div>
            <div className="mb-5">
              <label htmlFor="about_me" className="block mb-2 text-sm font-bold">
                What are you into?
              </label>
              <label htmlFor="about_me" className="block mb-2 text-xs font-normal text-gray-500">
                Make it easy htmlFor others to get a sense of who you are.
              </label>
              <textarea
                type="text"
                className="form-control"
                id="about_me"
                name="about_me"
                value={formData.about_me}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="Bio"
                rows={3}
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="personal_tags" className="block mb-2 text-sm font-bold">
                I enjoy
              </label>
              <label htmlFor="personal_tags" className="block mb-2 text-xs font-normal text-gray-500">
                Adding your interest is a great way to find like-minded connections.
              </label>
              <div className="flex flex-wrap gap-3 mt-4 text-sm">
                {tags.map((tag, index) => (
                  <div key={`${tag}_${index}`} className="grid-span-1 text-sm ">
                    <div
                      onClick={() => {
                        if (selectedTags.find((t) => t === tag)) {
                          setSelectedTags(selectedTags.filter((t) => t !== tag))
                          handleChange({
                            target: { name: 'interests', value: selectedTags.filter((t) => t !== tag).join(',') }
                          })
                        } else {
                          setSelectedTags([...selectedTags, tag])
                          handleChange({ target: { name: 'interests', value: [...selectedTags, tag].join(',') } })
                        }
                      }}
                      className={classNames(
                        'rounded p-1 text-sm cursor-pointer text-center',
                        selectedTags.find((t) => t === tag) ? 'bg-purple-300 hover:bg-purple-400' : 'bg-[#e0d8d8] '
                      )}
                    >
                      <h4 className="text-xs p-1">{tag}</h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-5">
              <label htmlFor="age_range_id" className="block mb-2 text-sm font-bold">
                Age Range
              </label>
              <label htmlFor="age_range_id" className="block mb-2 text-xs font-normal text-gray-500">
                Select the age range that best describes you.
              </label>
              <div className="border-radius-8">
                <select
                  className="form-control"
                  id="age_range_id"
                  name="age_range_id"
                  value={formData.age_range_id}
                  onChange={handleChange}
                >
                  {AGES.map((age, index) => (
                    <option key={age} value={index + 1}>
                      {age}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button
              type="button"
              onClick={onUpdateProfile}
              className="btn profile-button mb-5"
              id="update-profile"
              disabled={!isFormValid || isImgUploading}
            >
              Update Profile
            </button>
          </form>

          <div className="mt-6 text-left text-zinc-800 text-base font-medium font-roboto leading-7">
            Change Password
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="otp" className="form-label">
                Current Password
              </label>
              <input
                type="password"
                className="form-control"
                id="currentPassword"
                name="currentPassword"
                value={formData.currentPassword}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="Current Password"
                required
              />
              <div className="text-sm text-red-500">{errors?.currentPassword}</div>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="New Password"
                required
              />
              <div className="text-sm text-red-500">{errors?.password}</div>
            </div>
            <div className="mb-3">
              <label htmlFor="cell" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="Confirm Password"
                required
              />
              <div className="text-sm text-red-500">{errors?.confirmPassword}</div>
            </div>
            <button type="submit" className="btn profile-button mb-5" id="change-password" disabled={!isFormValid}>
              Change Password
            </button>

            <p className="text-center">
              <Link to="/forgot-password" className="text-decoration-none text-black">
                Forgot the password?
              </Link>
            </p>
          </form>
        </section>

        <div className="flex justify-center">
          <button className="btn logout mb-5" type="submit" id="logout-button" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}

export default Profile
