import React, { useState } from 'react'
import './createProfile.css'

import { uploadImage } from '../../services/eventService'
import { createProfile } from '../../services/userService'
import { showToast } from '../../services/toastService'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons'
import { AGES } from '../../config/userData'

const generateUsername = () => {
  const userInfo = localStorage.getItem('user')
  if (!userInfo) {
    return ''
  }

  try {
    const parsedUser = JSON.parse(userInfo)
    if (!parsedUser || !parsedUser.email) {
      return ''
    }
    const username = parsedUser.email.split('@')[0] + Math.floor(Math.random() * 1000)
    return username
  } catch (error) {
    console.error('Error parsing user info:', error)
    return ''
  }
}

export default function CreateProfile() {
  const navigate = useNavigate()
  const [isImgUploading, setIsImgUploading] = useState(false)
  const fileInputRef = React.createRef()

  const [formData, setFormData] = useState({
    age_range_id: 1,
    events_publicy: 'everyone',
    username: generateUsername(),
    imageUrl: '',
    name: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).fullName : ''
  })

  const updateFormData = (data) => {
    setFormData({ ...formData, ...data })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await createProfile({ ...formData })
    if (res.success) {
      showToast('Profile created successfully', 'success')
      navigate('/personalize-tags')
    } else {
      showToast(res.message, 'error')
    }
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    setIsImgUploading(true)
    try {
      const formData = new FormData()
      formData.append('image', file)
      const url = await uploadImage(formData)
      updateFormData({ imageUrl: url.data.imageUrl })
    } catch (error) {
      console.error('Error uploading the image:', error)
    } finally {
      setIsImgUploading(false)
    }
  }

  const onAddImage = () => {
    fileInputRef.current.click()
  }

  return (
    <div className="container">
      <div id="creatProfile" className="w-full">
        <div className="m-6">
          {' '}
          <a href="/">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          </a>
          <div className="text-center">Your Profile</div>
        </div>
        <main>
          <form className="max-w-sm mx-auto" onSubmit={handleSubmit}>
            <div className="mt-4"></div>
            <div className="flex items-center">
              <div>
                <img src={formData.imageUrl || '/assets/images/userprofile.png'} width={100} alt="profile" />
              </div>
              <button
                className="mx-4"
                onClick={onAddImage}
                disabled={isImgUploading}
                style={{ border: 'none', background: 'none' }}
              >
                <div className="text-primary border-bottom-primary add-picture">Add Your Picture</div>
              </button>
              <input type="file" ref={fileInputRef} onChange={handleFileChange} className="hidden" />
            </div>
            <div className="mb-2">
              <span htmlFor="name" className="col-3"></span>
              <input
                type="hidden"
                className="form-control ml-2"
                id="name"
                name="name"
                value={formData.name}
                onChange={(e) => updateFormData({ name: e.target.value })}
                placeholder="Full Name"
              />
            </div>
            <div className="mb-3">
              &nbsp; &nbsp;<h2>What should we call you? </h2>
              <label for="Username" className="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                <p className="instructions">
                  This will be how you appear to others on GreatNight. <br></br> Don't worry you can change this later.
                </p>
              </label>
              <input
                type="text"
                className="text-sm rounded w-full p-2.5 bg-slate-50"
                id="Username"
                name="Username"
                value={formData.username}
                onChange={(e) => updateFormData({ username: e.target.value })}
                placeholder="Username"
                required
              />
            </div>
            <div className="mb-3">
              <label for="Username" className="block mb-1 text-base font-medium text-gray-900 dark:text-white">
                &nbsp; &nbsp;<h2>Bio:</h2>
                <p className="instructions">Tell us a little about yourself so we understand your vibe.</p>
              </label>
              <textarea
                type="text"
                className="text-sm rounded w-full p-2.5 bg-slate-50 h-24"
                id="bio"
                name="bio"
                rows="4"
                cols="50"
                onChange={(e) => updateFormData({ bio: e.target.value })}
                value={formData.bio}
                placeholder=" "
                maxLength={150}
                required
              />
            </div>
            {/* <div className="mb-3">
              <label for="age_range" className="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                &nbsp;<h2>Age Range:</h2>
              </label>
              <div className="border-radius-8">
                <select
                  className="form-control text-sm rounded w-full p-2.5 bg-slate-50"
                  id="age_range_id"
                  name="age_range_id"
                  value={formData.age_range_id}
                  onChange={(e) => {
                    updateFormData({ age_range_id: e.target.value })
                  }}
                >
                  {AGES.map((age, index) => (
                    <option key={age} value={index}>
                      {age}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}
            <div className=" align-items-center mb-3">
              <div className="d-flex justify-start align-items-center">
                {['everyone', 'friends', 'justme'].map((events_publicy) => (
                  <div key={events_publicy} className="mx-2" onClick={() => updateFormData({ events_publicy })}>
                    <input
                      type="hidden"
                      id={events_publicy}
                      name="events_publicy"
                      checked={formData.events_publicy === events_publicy}
                      onChange={() => {
                        updateFormData({ events_publicy })
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <button type="submit" className="next-btn w-full block" id="continue" style={{ marginBottom: '15px' }}>
              Next
            </button>
          </form>
        </main>
      </div>
    </div>
  )
}
