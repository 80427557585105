import { createContext, useEffect, useState, useRef } from 'react'
import { io } from 'socket.io-client'
import { useNavigate } from 'react-router-dom'
import useMessageStore from '../store/messageStore'
import config from '../config/config'
import { showToastWithAction } from '../services/toastService'
import { getUnreadStatus } from '../services/chatService'
import { useParams } from 'react-router-dom'

const _socket = io(config.BASE_SERVER_URL, {
  reconnection: true,
  reconnectionDelay: 5000,
  reconnectionAttempts: 10,
  forceNew: true,
  transports: ['websocket'],
  upgrade: false
})

export const SocketContext = createContext()

export const SocketProvider = ({ children }) => {
  const [socket] = useState(() => _socket)
  const navigate = useNavigate()
  const addNotification = useMessageStore((state) => state.addNotification)
  const addMessage = useMessageStore((state) => state.addMessage)
  const setUnreadMessages = useMessageStore((state) => state.setUnreadMessages)

  const isNotificationEnabled = useMessageStore((state) => state.isNotificationEnabled)
  const isNotificationEnabledRef = useRef(isNotificationEnabled)
  const user = JSON.parse(localStorage.getItem('user'))
  const { roomId } = useParams()
  const roomIdRef = useRef(roomId)

  const fetchUnreadStatus = async () => {
    const response = await getUnreadStatus(user.userId)
    if (response?.success) {
      setUnreadMessages(response.unreadMessages)
    }
  }

  useEffect(() => {
    isNotificationEnabledRef.current = isNotificationEnabled
  }, [isNotificationEnabled])

  useEffect(() => {
    roomIdRef.current = roomId
  }, [roomId])

  useEffect(() => {
    if (user?.userId) {
      fetchUnreadStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId])

  const handleReceiveMessage = (data) => {
    const roomId = data.roomId
    const { messages } = useMessageStore.getState()
    const roomIdsForUser = Object.keys(messages)
    if (roomIdsForUser.includes(roomId)) {
      addMessage(data.roomId, data)
      if (data.sender_id !== user?.userId) {
        if (roomIdRef.current !== data.roomId) {
          addNotification(data)
          if (isNotificationEnabledRef.current) {
            showToastWithAction(() => {
              navigate(`/chatroom/${data.roomId}`)
            })
          }
        } else {
          setTimeout(() => {
            const element = document.getElementById('messages-container')
            if (element) {
              element.scrollTop = element.scrollHeight
            }
          }, 2000)
        }
      }
    }
  }

  useEffect(() => {
    socket.on('connect', () => {
      console.log('connected to server', socket.id)
      const engine = socket.io.engine
      console.log('engine.transport.name', engine.transport.name)
    })

    socket.on('receive_message', (data) => {
      handleReceiveMessage(data)
    })

    return () => {
      socket.off('receive_message')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
}
