import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getVibesList } from '../../services/eventService'
import Header from '../common/header/Header'
import { showToast } from '../../services/toastService'
import { followUser, unfollowUser } from '../../services/userService'
import { VibeEventCard } from './card/'
import { LoadingIndicator } from '../common/loading'

const VibesList = () => {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const [vibes, setVibes] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getVibes = async () => {
      const response = await getVibesList()
      if (response.success) {
        if (response.data.vibes.length > 0) {
          setVibes(response.data.vibes)
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
    getVibes()
    // eslint-disable-next-line
  }, [])

  const handleFollow = async (follower_id, name) => {
    if (user && user.userId) {
      if (user.userId === follower_id) {
        showToast('You cannot follow yourself', 'error')
      }
      const response = await followUser(user.userId, follower_id)
      if (response.success) {
        showToast(`You are now following ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            event.Event.User.is_following = 1
          }
          return event
        })
        setVibes(updatedEvents)
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  const handleUnfollow = async (follower_id, name) => {
    if (user && user.userId) {
      if (user.userId === follower_id) {
        showToast('You cannot unfollow yourself', 'error')
      }
      const response = await unfollowUser(user.userId, follower_id)
      if (response.success) {
        showToast(`You have unfollowed ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            if (event.Event && event.Event.User) {
              event.Event.User.is_following = 0
            }
          }
          return event
        })
        setVibes(updatedEvents)
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  const VibeSection = ({ vibes }) =>
    vibes?.length > 0 && (
      <>
        <hr className="mt-5" />
        <hr className="mb-3" />
        <div className="container grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {vibes.map((vibe, index) => (
            <VibeEventCard
              key={index}
              vibe={vibe}
              showFollowButton={true}
              onFollowUpdate={(data) =>
                data.action === 'follow'
                  ? handleFollow(data.user_id, vibe.Event.User.name)
                  : handleUnfollow(data.user_id, vibe.Event.User.name)
              }
              showUserName={true}
            />
          ))}
        </div>
      </>
    )

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div id="vibeslist">
      <Header title="Vibes" showLink={true} />
      <main className="">
        <div>
          <div className="text-[#515151] text-sm font-normal font-roboto leading-snug">
            Curate events, share favorites, discover picks, and follow users for tailored recommendations
          </div>
          <div>
            In order to curate events, you must upload a{' '}
            <a className="underline" href="/promoter/profile">
              profile image
            </a>{' '}
          </div>
        </div>
        {vibes?.length > 0 ? (
          <VibeSection vibes={vibes} />
        ) : (
          <div className="mt-10 text-center">
            <h2 className="text-center">No vibes found</h2>
          </div>
        )}
      </main>
    </div>
  )
}

export default VibesList
