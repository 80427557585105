import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { getUpcomingEvents } from '../../../services/eventService'
import AdComponent from '../ads'
import Header from '../../common/header/Header'
import { LoadingIndicator } from '../../common/loading'
import { EventCard } from '../card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { classNames } from '../../../utils'

const TagsGroup = [
  { name: 'Trending', value: 'trending' },
  { name: 'Today', value: 'dayOfWeek' },
  { name: 'For You', value: 'for-you' },
  { name: 'All', value: 'all' }
]

function Events() {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)

  const [selectedTagGroup, setSelectedTagGroup] = useState(null)
  const [allTags, setAllTags] = useState([])
  const [tags, setTags] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)
  const [filteredEvents, setFilteredEvents] = useState([])
  const [myTags, setMyTags] = useState([])

  useEffect(() => {
    const getEvents = async () => {
      if (user && user.userId && user.role) {
        const response = await getUpcomingEvents(user.userId)
        if (response.success) {
          const combinedEvents = response.data.events.map((event) => {
            return {
              ...event,
              tags: event.tags.split(',').map((tag) => tag.trim()),
              dayOfWeek: moment(event.start).format('ddd')
            }
          })
          setEvents(combinedEvents)
          setMyTags(combinedEvents?.[0]?.User?.Profile?.interests?.split(',')?.map((tag) => tag.trim()) ?? [])
          if (Array.isArray(response.data.tags)) {
            const tagFrequency = {}
            response.data.tags.forEach((tag) => {
              if (tagFrequency[tag]) {
                tagFrequency[tag]++
              } else {
                tagFrequency[tag] = 1
              }
            })
            const sortedTags = Object.keys(tagFrequency).sort((a, b) => tagFrequency[b] - tagFrequency[a])
            setAllTags(sortedTags)
            setSelectedTagGroup(TagsGroup[0])
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
    getEvents()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selectedTagGroup) {
      const type = selectedTagGroup.value
      switch (type) {
        case 'trending':
          setTags(allTags.slice(0, 10))
          filterEventsByTag({ type: type, value: allTags?.[0] ?? null })
          break
        case 'dayOfWeek':
          const dayOfWeekToday = moment().format('ddd')
          setTags(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])
          setSelectedTag(dayOfWeekToday)
          filterEventsByTag({ type: type, value: dayOfWeekToday })
          break
        case 'for-you':
          setTags(myTags?.slice(0, 4) ?? [])
          filterEventsByTag({ type: type, value: myTags?.[0] ?? null })
          break
        case 'all':
          setTags(allTags)
          filterEventsByTag({ type: type, value: allTags?.[0] ?? null })
          break
        default:
          break
      }
    }
    // eslint-disable-next-line
  }, [selectedTagGroup])

  const filterEventsByTag = ({ type, value }) => {
    setSelectedTag(value)
    if (!value) {
      setFilteredEvents(events)
      setSelectedTag(null)
      return
    }
    switch (type) {
      case 'trending':
        setFilteredEvents(events.filter((event) => event.tags.includes(value)))
        break
      case 'dayOfWeek':
        setFilteredEvents(events.filter((event) => moment(event.start).format('ddd') === value))
        break
      case 'for-you':
        setFilteredEvents(events.filter((event) => event.tags.includes(value)))
        break
      case 'all':
        setFilteredEvents(events.filter((event) => event.tags.includes(value)))
        break
      default:
        break
    }
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div id="events" className="min-h-screen max-w-full overflow-x-hidden">
      <Header />
      <main className="px-4 w-full max-w-full">
        <div className="max-w-screen-sm mx-auto w-full">
          {tags.length > 0 && (
            <div className="tag-btn-container w-full">
              <span className="font-bold mb-3 block">Click a tag to find your vibe</span>

              {/* Tag group container */}
              <div className="w-full mb-3 overflow-hidden">
                <div className="overflow-x-auto scrollbar-hide -mx-4 px-4">
                  <div className="flex gap-2 pb-2 w-max">
                    {TagsGroup.map((tagGroup) => (
                      <button
                        key={tagGroup.value}
                        type="button"
                        onClick={() => setSelectedTagGroup(tagGroup)}
                        className={classNames(
                          'h-7 px-3 py-2  text-white text-sm rounded-[40px] justify-center items-center gap-1 inline-flex',
                          'transition-colors duration-200 ease-in-out',
                          selectedTagGroup.value === tagGroup.value
                            ? 'bg-violet-600 hover:bg-gray-800'
                            : 'bg-[#9095a0] hover:bg-gray-600'
                        )}
                      >
                        <span>{tagGroup.name}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Secondary tags container */}
              <div className="w-full overflow-hidden">
                <div className="overflow-x-auto scrollbar-hide -mx-4 px-4">
                  <div className="flex gap-2 pb-2 w-max">
                    {tags.length > 0 &&
                      tags?.map((tag, index) => (
                        <button
                          key={`tag-${index}`}
                          type="button"
                          onClick={() => {
                            filterEventsByTag({ type: selectedTagGroup.value, value: tag })
                          }}
                          className={classNames(
                            'h-6 px-3 py-2 text-sm rounded-[40px] border border-[#e0e0e0] justify-center items-center gap-1 inline-flex',
                            'transition-colors duration-200 ease-in-out',
                            selectedTag === tag
                              ? 'bg-purple-500 hover:bg-purple-600 text-white'
                              : 'bg-[#f3f4f6] hover:bg-gray-200 text-gray-800'
                          )}
                        >
                          <span>{tag}</span>
                        </button>
                      ))}
                  </div>
                </div>

                {selectedTag && (
                  <button
                    id="clear_tag"
                    className="text-sm my-2 px-3 py-1 hover:bg-gray-100 rounded-md transition-colors duration-200"
                    onClick={() => filterEventsByTag({ type: selectedTagGroup, value: null })}
                  >
                    <FontAwesomeIcon icon={faTimes} /> Clear Filter
                  </button>
                )}
              </div>
            </div>
          )}

          {/* Events content */}
          <div className="w-full">
            {filteredEvents.map((event, index) => (
              <div key={event.id || index} className="w-full">
                <EventCard event={event} />
                {(index + 1) % 5 === 1 && <AdComponent index={index} />}
              </div>
            ))}
            {filteredEvents.length === 0 && (
              <div className="flex justify-center items-center min-h-[50vh] w-full">
                <p className="text-center">
                  There are no events based on the filters you selected, please select another.
                </p>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  )
}

export default Events
