import api from './api'
import moment from 'moment-timezone'
import config from '../config/config'
import axios from 'axios'

const createEvent = async (formData) => {
  try {
    const response = await api.post('/event', formData)
    return response.data
  } catch (error) {
    throw error
  }
}

const getEventDetails = async (eventId) => {
  try {
    const response = await api.get(`/event/${eventId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const updateEvent = async (eventId, formData) => {
  try {
    const response = await api.put(`/event/${eventId}`, formData)
    return response.data
  } catch (error) {
    throw error
  }
}

const formatEventTime = (startTimestamp, endTimestamp) => {
  const startTime = moment.utc(startTimestamp)
  const endTime = moment.utc(endTimestamp)

  const formattedStartTime = startTime.format('ddd. MMM. D, hh:mm A')
  const formattedEndTime = endTime.format('ddd. MMM. D, hh:mm A')
  return `${formattedStartTime} - ${formattedEndTime}`
}

const formatEventDate = (startTimestamp) => {
  const startTime = moment.utc(startTimestamp)
  const formattedStartTime = startTime.format('ddd MMM.D')
  return `${formattedStartTime}`
}

const getEventDetailsBySlug = async (slug) => {
  try {
    const response = await api.get(`/event/slug/${slug}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const getEventsForUser = async (userId) => {
  try {
    const response = await api.get(`/event/user/${userId}`)
    return { success: true, data: response.data }
  } catch (error) {
    if (error.response?.status === 404) {
      return { success: false, data: null, errorMessage: 'No events found' }
    } else {
      return {
        success: false,
        data: null,
        errorMessage: 'There was an issue getting the events'
      }
    }
  }
}

const getUpcomingEvents = async (userId) => {
  try {
    const response = await api.get(`/event/upcoming/${userId}`)
    return { success: true, data: response.data }
  } catch (error) {
    if (error.response?.status === 404) {
      return { success: false, data: null, errorMessage: 'No events found' }
    } else {
      return {
        success: false,
        data: null,
        errorMessage: 'There was an issue getting the events'
      }
    }
  }
}

const uploadImage = async (data) => {
  try {
    const response = await api.post(`/event/s3/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return { success: true, data: response.data }
  } catch (error) {
    return { success: false, data: null }
  }
}

const deleteEvent = async (data) => {
  try {
    await api.delete(`/event/${data.eventId}/${data.promoterId}`)
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

const cancelEvent = async (eventId) => {
  try {
    await api.put(`/event/cancel/${eventId}`)
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

const rsvpForEvent = async () => {
  const invite = JSON.parse(localStorage.getItem('invite'))
  const user = JSON.parse(localStorage.getItem('user'))
  try {
    const data = {
      slug: invite.event,
      userId: user.userId
    }

    if (invite.influencerId) {
      data.influencerId = invite.influencerId
    }

    const response = await api.post('/event/rsvp', data)
    return { success: true, data: response.data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

const removeRsvpForEvent = async (rsvpId) => {
  if (!rsvpId) return { success: false }

  try {
    await api.delete(`/event/rsvp/${rsvpId}`)
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

const shareEventByEmail = async (email, eventSlug) => {
  if (!email || !eventSlug) {
    throw new Error('Email and event slug are required')
  }

  try {
    const response = await api.post('/event/share-event-email', {
      email,
      eventSlug
    })

    return response.data
  } catch (error) {
    console.error('Error sharing event via email:', error)

    throw new Error('Failed to share event via email')
  }
}

const sendPrivateEventInvitation = async (promoterId, userId, email, eventId, eventSlug) => {
  if (!promoterId || !userId || !email || !eventId || !eventSlug) {
    throw new Error('Promoter ID, User ID, email, event ID, and event slug are required')
  }

  try {
    const response = await api.post('/event/send-invitation', {
      promoterId,
      userId,
      email,
      eventId,
      eventSlug
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const setReminder = async (formData) => {
  try {
    const response = await api.post('/event/set-reminder', formData)
    return response.data
  } catch (error) {
    throw error
  }
}

const createVibe = async (formData) => {
  try {
    const response = await api.post('/event/vibe', formData)
    return response.data
  } catch (error) {
    throw error
  }
}

const removeVibe = async (vibeId) => {
  try {
    await api.delete(`/event/vibe/${vibeId}`)
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

const getVibesForUser = async (userId) => {
  try {
    const response = await api.get(`/event/vibes/${userId}`)
    return { success: true, data: response.data }
  } catch (error) {
    if (error.response?.status === 404) {
      return { success: false, data: null, errorMessage: 'No vibes found' }
    } else {
      return {
        success: false,
        data: null,
        errorMessage: 'There was an issue getting the vibes'
      }
    }
  }
}

const getAllVibes = async () => {
  try {
    const response = await api.get('/event/vibes')
    return { success: true, data: response.data }
  } catch (error) {
    if (error.response?.status === 404) {
      return { success: false, data: null, errorMessage: 'No vibes found' }
    } else {
      return {
        success: false,
        data: null,
        errorMessage: 'There was an issue getting the vibes'
      }
    }
  }
}

const getVibesList = async () => {
  try {
    const response = await api.get('/event/vibeslist')
    return { success: true, data: response.data }
  } catch (error) {
    return { success: false, data: null }
  }
}

// Generate event flyer image
const generateEventFlyer = async (formData) => {
  try {
    const response = await api.post(`/event/generate-flyer`, formData)
    return response.data
  } catch (error) {
    throw error
  }
}

const qrScanDataForRsvp = async (rsvpId, influencerId, userId) => {
  try {
    const response = await api.get(`event/ticket/scan?rsvpId=${rsvpId}&influencerId=${influencerId}&userId=${userId}`)
    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,
      data: null,
      error
    }
  }
}

const getEventDataFromImage = async (data) => {
  try {
    const response = await axios.post(`${config.NER_API_URL}/analyze-image`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error('Failed getEventDataFromImage:', error)
    return null
  }
}

const getEventDataFromText = async (data) => {
  try {
    const response = await axios.post(`${config.NER_API_URL}/analyze-description`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error('Failed getEventDataFromText:', error)
    return null
  }
}

const getPrivateEventDashboardData = async (prompter_id, event_id) => {
  try {
    const response = await api.get(`/event/private-event-dashboard/${prompter_id}/${event_id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const processInvitation = async (data) => {
  try {
    const response = await api.post('/event/process-invitation', data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getInvitationById = async (inviteId) => {
  try {
    const response = await api.get(`/event/invitation/${inviteId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export {
  createEvent,
  getEventDetails,
  updateEvent,
  getEventsForUser,
  getUpcomingEvents,
  uploadImage,
  deleteEvent,
  getEventDetailsBySlug,
  rsvpForEvent,
  removeRsvpForEvent,
  formatEventTime,
  formatEventDate,
  shareEventByEmail,
  sendPrivateEventInvitation,
  setReminder,
  generateEventFlyer,
  createVibe,
  removeVibe,
  getVibesForUser,
  getAllVibes,
  qrScanDataForRsvp,
  getEventDataFromImage,
  getEventDataFromText,
  cancelEvent,
  getVibesList,
  getPrivateEventDashboardData,
  processInvitation,
  getInvitationById
}
