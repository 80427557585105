import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Register from '../components/ticket/register/Register'
import { createUser, followUser } from '../services/userService'
import Cookies from 'js-cookie'

function RegisterContainer() {
  const navigate = useNavigate()
  const invite = JSON.parse(localStorage.getItem('invite'))

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    cell: '',
    password: '',
    confirmPassword: '',
    termsOfService: false,
    privacyPolicy: false
  })
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    cell: '',
    password: '',
    confirmPassword: ''
  })
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [navigate])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const handleInputBlur = (e) => {
    const { name, value } = e.target
    let newErrors = { ...errors }

    switch (name) {
      case 'name':
        newErrors.name = value.trim() === '' ? 'Your name is required' : ''
        break
      case 'email':
        newErrors.email = value === '' ? 'Email is required' : ''
        break
      case 'cell':
        newErrors.cell = value === '' ? 'Phone is required' : ''
        break
      case 'password':
        newErrors.password = value === '' ? 'Password is required' : ''
        break
      case 'confirmPassword':
        newErrors.confirmPassword = value === '' ? 'Confirm password is required' : ''
        break
      default:
        break
    }

    if (name === 'confirmPassword' && value !== formData.password) {
      newErrors.confirmPassword = 'Passwords do not match'
    }

    setErrors(newErrors)
  }

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      formData.role = 'eventgoer'

      const { confirmPassword, ...userData } = formData

      const result = await createUser(userData)

      if (result.success) {
        Cookies.set('gn_userToken', result.data.token)
        localStorage.setItem('user', JSON.stringify({ ...result.data, email: userData.email }))

        const redirectTo = localStorage.getItem('redirect')
        const inviteData = localStorage.getItem('invite')
        const influencerId = inviteData ? JSON.parse(inviteData).influencerId : null

        if (redirectTo && influencerId) {
          const userId = result.data?.userId
          if (userId) {
            if (userId === influencerId) {
              console.log('🚀 ~ Register ~ You cannot follow yourself:')
              return
            }

            const response = await followUser(userId, influencerId)
            if (response.success) {
              console.log('🚀 ~ Register ~ You are now following the influencer:', influencerId)
            } else {
              console.log('🚀 ~ Register ~ Error following the influencer:', response.message)
            }
          }
        }

        setMessage('Registration successful.')
        setShowToast(true)
        setLoading(false)
        setTimeout(() => {
          navigate('/create-profile')
        }, 2000)
      } else {
        setLoading(false)
        setMessage(`Registration failed. ${result.message}`)
        setShowToast(true)
      }
    } catch (error) {
      console.error('Error registering user:', error)
      setMessage(`Error registering user: ${error}`)
      setShowToast(true)
      setLoading(false)
    }
  }

  return (
    <Register
      onSubmit={handleSubmit}
      loading={loading}
      formData={formData}
      invite={invite}
      handleChange={handleChange}
      handleInputBlur={handleInputBlur}
      errors={errors}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
    />
  )
}

export default RegisterContainer
