import React from 'react'
import { Link } from 'react-router-dom'

export const VibeEventCard = ({ vibe }) => {
  if (!vibe) return null

  return (
    <div className="event relative max-w-[720px] mx-auto">
      {vibe.Event.flyer && (
        <Link to={`/promoter/vibes/${vibe.user_id}`} className="text-decoration-none">
          <div className="relative mb-0">
            <div className="z-30 card-img-top">
              <div className="w-24 h-24 rounded-full overflow-hidden">
                <img
                  className="h-full w-full"
                  src={vibe.User?.Profile?.profile_picture_url || '/assets/images/icons/user.png'}
                  alt="Avatar"
                />
              </div>
              {/* <div className="overlay absolute top-0 left-0 z-40 w-full">
                <button className="items-left text-left text-white  ml-3 bg-blend-darken opacity-100 w-full py-1">
                  {vibe.User?.name}
                </button>
              </div> */}
              <div className="absolute bottom-0 left-0 z-40 w-full">
                <div className="bg-gray-800/40">
                  {' '}
                  {/* <button className="items-left justify-center text-white w-full py-2">
                    <div className="font-medium">
                      {' '}
                      {vibe.User?.Profile?.about_me && vibe.User?.Profile?.about_me.slice(0, 20)}
                    </div>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}
