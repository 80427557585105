import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Map from '../../common/map/Map'
import {
  cancelEvent,
  deleteEvent,
  formatEventTime,
  getInvitationById,
  processInvitation
} from '../../../services/eventService'
import { joinToChatRoom } from '../../../services/chatService'
import { LoadingIndicator } from '../../common/loading'
import Header from '../../common/header/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTicket } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import SendInvitationModal from './SendInvitationModal'
import EventDashboardModal from './EventDashboardModal'
import { showToast } from '../../../services/toastService'

function EventDetails({
  eventDetails,
  loading,
  user,
  handleRsvpForRegisteredUser,
  handleNotAttending,
  handleShareEvent,
  handleSaveVibe,
  handleUnsaveVibe,
  setReminderHandler,
  setShowToast,
  toastMessage,
  savedEvent,
  rsvp,
  isJoinedChatRoom
}) {
  const invite = JSON.parse(localStorage.getItem('invite'))
  // const registerLink = `ticket/register`

  const navigate = useNavigate()
  const { inviteId, recipientId } = useParams()
  const address = `${eventDetails?.street}, ${eventDetails?.city}, ${eventDetails?.state}, ${eventDetails?.country}`
  const inviteData = localStorage.getItem('invite')
  const influencerId = inviteData ? JSON.parse(inviteData).influencerId : null
  const remindersArray = JSON.parse(localStorage.getItem('reminders')) || []

  const [showModal, setShowModal] = useState(false)
  const [showEDModal, setShowEDModal] = useState(false)
  const [status, setStatus] = useState('')
  const [disabled, setDisabled] = useState(false)

  const deleteEventHandler = async () => {
    const data = {
      eventId: eventDetails?.event_id,
      promoterId: JSON.parse(localStorage.getItem('user'))?.userId
    }
    const response = await deleteEvent(data)
    console.log('deleteEventHandler response:', response)
    navigate('/promoter/')
  }

  const cancelEventHandler = async () => {
    const response = await cancelEvent(eventDetails?.event_id)
    console.log('cancelEventHandler response:', response)
    navigate('/promoter/my-event')
  }

  // eslint-disable-next-line
  const handleJoinChat = async () => {
    const payload = {
      eventId: eventDetails?.event_id,
      userId: JSON.parse(localStorage.getItem('user'))?.userId
    }
    const res = await joinToChatRoom(payload)
    if (res.success) {
      navigate(`/chatroom/${res.roomId}`, {
        state: {
          eventId: eventDetails?.event_id,
          eventName: eventDetails?.name
        }
      })
    }
    console.log('handleJoinChat payload:', res, payload)
  }

  const handleInvitation = async (status) => {
    const data = {
      inviteId,
      status
    }
    const response = await processInvitation(data)
    showToast(response.message, status === 'Y' ? 'success' : 'error')
    setStatus(status)
  }

  const getInvitationStatus = async (inviteId) => {
    const reponse = await getInvitationById(inviteId)
    if (reponse && reponse.invitation) {
      setStatus(reponse.invitation.status)
    }
  }

  const handleRedirect = () => {
    localStorage.setItem('redirect', window.location.pathname)
    navigate('/')
  }

  useEffect(() => {
    inviteId && getInvitationStatus(inviteId)

    if (remindersArray.length > 0) {
      const reminderExists = remindersArray.some((reminder) => reminder.eventId === eventDetails?.event_id)
      if (reminderExists) {
        setDisabled(true)
      }
    }
    // eslint-disable-next-line
  }, [inviteId, remindersArray])

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div id="event-details-wrapper">
      <Header showLink={true} title="event details" />
      <div id="event-img" className="container mb-4">
        <span className="inter-400 text-sm text-neutral-100 p-2 bg-neutral-800 text-center block">
          {formatEventTime(eventDetails?.start, eventDetails?.end)}
        </span>
        {eventDetails?.flyer && (
          <img src={eventDetails?.flyer} className="img-fluid w-100 rounded-3 " alt="Event Flyer" />
        )}
      </div>
      <div className="container">
        <div className="flex flex-nowrap my-2">
          <h1 className="epilogue-800 text-xl leading-tight text-pretty mt-3 mb-1 w-[calc(100%-120px)]">
            {eventDetails?.name || 'N/A'}
          </h1>
          <button
            className={`py-2 mt-2 flex items-center gap-2 ${disabled ? 'bg-neutral-300' : 'bg-neutral-800'} text-white px-3 rounded text-sm w-[120px] h-[35px]`}
            onClick={setReminderHandler}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faBell} />
            <span>Remind Me</span>
          </button>
        </div>
        <p
          className="inter-400 mb-1 font-light text-sm"
          dangerouslySetInnerHTML={{ __html: eventDetails?.description }}
        ></p>

        <section id="location" className="mb-8 text-neutral-600">
          <div id="map" className="w-full mt-4">
            <Map address={address} />
            <h2 className="epilogue-700 mt-2 text-sm">Location</h2>
            <p className="inter-400 text-sm mb-2 text-pretty">
              {eventDetails?.street} {eventDetails?.city}, {eventDetails?.state} {eventDetails?.zip}
            </p>
          </div>
        </section>

        {status === 'NR' &&
          inviteId &&
          recipientId &&
          (eventDetails?.is_public === 0 || user?.userId !== eventDetails?.user_id) && (
            <div className="text-center flex items-center justify-center mb-4 gap-4">
              <div className="w-1/2">
                <Link
                  onClick={() => handleInvitation('Y')}
                  className="text-decoration-none button button--primary text-white block text-center"
                  id="send-invitation"
                >
                  Accept
                </Link>
              </div>
              <div className="w-1/2">
                <Link
                  onClick={() => handleInvitation('R')}
                  id="private-event-dashboard"
                  className="text-decoration-none button button--tertiary block text-center text-white"
                >
                  Reject
                </Link>
              </div>
            </div>
          )}

        <div className="mb-4">
          {!user && invite?.influencerId && (
            <button
              onClick={handleRedirect}
              className="w-full text-decoration-none button button--secondary text-center text-white block"
              id="book-event"
            >
              <FontAwesomeIcon icon={faTicket} /> I'm Going
            </button>
          )}
          {user && (
            <button
              onClick={rsvp ? handleNotAttending : handleRsvpForRegisteredUser}
              className="w-full text-decoration-none button button--secondary text-center text-white block"
              id="book-event"
            >
              <FontAwesomeIcon icon={faTicket} /> {rsvp ? 'Not Attending' : "I'm Going"}
            </button>
          )}
        </div>

        {!user && eventDetails?.shareable === 'Y' && (
          <div className="text-center mb-4">
            <Link
              to={'/'}
              className="text-decoration-none button button--tertiary text-white block text-center"
              id="share-event"
            >
              <FontAwesomeIcon icon={faCopy} /> Share Event
            </Link>
          </div>
        )}

        {user && ((influencerId && eventDetails?.shareable === 'Y') || !influencerId) && (
          <div className="text-center mb-4">
            <Link
              onClick={handleShareEvent}
              className="text-decoration-none button button--tertiary text-white block text-center"
              id="share-event"
            >
              <FontAwesomeIcon icon={faCopy} /> Share Event
            </Link>
          </div>
        )}

        {eventDetails?.is_public === 0 && user?.userId === eventDetails?.user_id && (
          <div className="text-center flex items-center justify-center mb-4 gap-4">
            <div className="w-1/2">
              <Link
                onClick={() => setShowModal(true)}
                className="text-decoration-none button button--secondary text-white block text-center"
                id="send-invitation"
              >
                Send Invitation
              </Link>
            </div>
            <div className="w-1/2">
              <Link
                onClick={() => setShowEDModal(true)}
                id="private-event-dashboard"
                className="text-decoration-none button button--secondary block text-center text-white"
              >
                Private Event Dashboard
              </Link>
            </div>
          </div>
        )}

        <div className="text-center flex items-center justify-center mb-4 gap-4">
          <div className="w-1/2">
            {eventDetails?.is_public === 1 && (
              <div
                onClick={!savedEvent ? handleSaveVibe : handleUnsaveVibe}
                id="book-event"
                className="cursor-pointer text-decoration-none button button--secondary block text-center text-white"
                style={{ pointerEvents: 'auto', opacity: 1 }}
              >
                {savedEvent ? 'Unsave' : 'Save'}
              </div>
            )}
          </div>
          {user?.userId === eventDetails?.user_id && (
            <div className="w-1/2">
              <Link
                to={`/promoter/event/edit/${eventDetails?.event_id}`}
                id="book-event"
                className="text-decoration-none button button--secondary block text-center text-white"
              >
                Edit Event
              </Link>
            </div>
          )}
        </div>

        {user?.userId === eventDetails?.user_id && (
          <div className="text-center flex items-center justify-center mb-4 gap-4">
            <div className="text-center w-1/2">
              <Link
                className="text-decoration-none button button--primary block text-center"
                onClick={deleteEventHandler}
              >
                Delete Event
              </Link>
            </div>
            <div className="text-center w-1/2">
              <Link
                className="text-decoration-none button button--primary block text-center"
                onClick={cancelEventHandler}
              >
                Cancel Event
              </Link>
            </div>
          </div>
        )}
      </div>
      {user?.userId && (eventDetails?.is_public === 0 || user?.userId === eventDetails?.user_id) && (
        <>
          <SendInvitationModal showModal={showModal} setShowModal={setShowModal} eventDetails={eventDetails} />
          <EventDashboardModal
            showEDModal={showEDModal}
            setShowEDModal={setShowEDModal}
            eventDetails={eventDetails}
            handleJoinChat={handleJoinChat}
          />
        </>
      )}
    </div>
  )
}

export default EventDetails
