import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const messageStore = (set, get) => ({
  notifications: [],
  messages: {},
  isNotificationEnabled: true,
  unreadMessages: [],
  numOfNotifications: 0,

  setMessages: (roomId, messages) => {
    set((state) => ({
      messages: {
        ...state.messages,
        [roomId]: messages
      }
    }))
  },
  addMessage: (roomId, message) => {
    set((state) => ({
      messages: {
        ...state.messages,
        [roomId]: [...(state.messages[roomId] ?? []), message]
      }
    }))
  },
  getMessages: (roomId) => {
    return get().messages[roomId] || []
  },
  addRoomIdToMessages: (roomId) => {
    if (!get().messages[roomId]) {
      set((state) => ({
        messages: {
          ...state.messages,
          [roomId]: []
        }
      }))
    }
  },
  addNotification: (notification) => {
    set((state) => ({
      unreadMessages: state.unreadMessages.map((message) => {
        if (message.roomId === notification.roomId) {
          return {
            ...message,
            unreadCount: message.unreadCount + 1
          }
        }
        return message
      }),
      numOfNotifications: state.numOfNotifications + 1
    }))
  },
  resetUnreadCountForRoom: (roomId) => {
    const _numOfNotifications =
      get().numOfNotifications - (get().unreadMessages?.find((message) => message.roomId === roomId)?.unreadCount ?? 0)
    set((state) => ({
      unreadMessages: state.unreadMessages.map((message) => {
        if (message.roomId === roomId) {
          return {
            ...message,
            unreadCount: 0
          }
        }
        return message
      }),
      numOfNotifications: _numOfNotifications
    }))
  },
  resetAllNotifications: () => {
    set({
      notifications: [],
      numOfNotifications: 0
    })
  },
  toggleNotification: () => {
    set((state) => ({
      isNotificationEnabled: !state.isNotificationEnabled
    }))
  },
  setUnreadMessages: (unreadMessages) => {
    set({
      numOfNotifications: unreadMessages.reduce((acc, curr) => acc + curr.unreadCount, 0),
      unreadMessages
    })
  }
})

const useMessageStore = create(
  persist(messageStore, {
    name: 'messages-storage'
  })
)

export default useMessageStore
